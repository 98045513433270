import React from "react";
import SimplePage from "../components/SimplePage";

function Innovation() {
  return (
    <div className="px-5 lg:px-24 pt-12">
      <SimplePage
        type="innovation"
        cards={["strategy", "design", "monetization"]}
        title={[
          "We Develop Ecosystems where",
          "Innovation Occurs - Now, Today",
        ]}
        paragraphs={[
          "Innovation must embrace the ethic of evaluating social outcomes. Whether through Innovation labs or conversations on the street with people from all backgrounds, innovation evolves where new destinations, human experience and social responsibility merge.",
          "We create new, transformational ways of doing business and moving products and services into the digital world. Our innovation projects have included several industry firsts. We take existing technologies or new technologies and shape them in unique ways that allow our clients to stand out from the crowd. Innovation builds and iterates upon foundations that exist; or, it can move backwards through new ideas to finds new ways to connect with existing requirements, or, a hoped-for outcome; or, it can start with the ubiquitous eternal question, “what if?” and create entirely new models, products and services.",
          "These are some of the questions we ask with our clients when shaping what can be done and what’s next: Can I utilize what I have in place now differently? How do we develop, deploy, scale and continually test new conceptual models that have yet to be adopted in the marketplace? How can I rethink my current strategies via new technologies for existing products, services and solutions? What new technologies are coming to market that can help move my business forward?",
          " ",
          "Mini Case Studies:",
          "Client: Global Software Company. Developed a Multi-Channel Customer Engagement App that connected, in real-time, a customer with an urgent hardware issue, a customer service representative, a field service engineer and a parts availability database within a single-page multi-user experience. This was developed as a prototype to demonstrate how their clients can create next-gen composite customer service applications and workflows.",
          "Client: National Food Broker. Developed the first application workflow that integrated Salesforce.com and ESRI to show product sales via dashboard heat maps for a variety stakeholder requirements.",
        ]}
      />
    </div>
  );
}

export default Innovation;
