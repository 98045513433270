import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";

const Card = (props) => {
  const { type } = props;
  return (
    <Button>
      <Link to={`/ourwork/${type}`} onClick={() => window.scrollTo(0, 0)}>
        <img
          src={require(`../assets/${type}-card.png`)}
          alt="card"
          width="100%"
          height="100%"
        />
      </Link>
    </Button>
  );
};

export default Card;
