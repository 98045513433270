import React from "react";

import Card from "../components/Card";

const waves = require("../assets/waves.png");

const AboutUs = () => {
  return (
    <div>
      <div className="px-5 block lg:hidden text-center mt-6">
        <p className="tagline-mobile">Advancing Elegant, Noise-Free</p>
        <p className="tagline-mobile">Digital Experiences</p>
      </div>
      {/* <div style={{ }}> */}
      <div
        style={{ fontSize: 22, width: "100vw" }}
        className="grid sm:grid-cols-1 lg:grid-cols-2 px-5 lg:pl-24 lg:pr-24 pt-6 pb-6"
      >
        <div className="span-col-1 pr-0 lg:pr-12">
          <p className="mb-8">
            We Believe Listening and Brand Integrity are Essential to Impactful
            Visual Design.
          </p>
          <p className="mb-8">
            We Identify and Resolve issues that Block Momentum.
          </p>
          <p className="mb-8">
            We Create Value through Tactical Clarity and an Explorer's Mindset.
          </p>
          <p>We Develop Ecosystems where Innovation Occurs-Now, Today.</p>
        </div>

        <div
          className="span-col-1 lg:border-l-3 lg:border-main-color pl-0 lg:pl-12 mt-12 lg:mt-0 lg:text-justify"
          style={{
            borderLeftColor: "#fa4616",
            borderLeftWidth: window.innerWidth > 500 ? 3 : 0,
          }}
        >
          <p>
            We never lead with assumptions that pigeonhole our clients into a
            technology platform, preconceived user experience or visual design
            program. We look at your product and services against the background
            of your existing audience and potential marketplace. You are the
            expert on your product and service. We create strategy, design and
            user experience programs to help you do things more effectively,
            extend your offering into places you have never been and expand your
            brand experience to your existing marketplace with refreshing
            vibrance.
          </p>
        </div>
      </div>
      {/* </div> */}
      <div
        style={{
          display: "flex",
          marginTop: "auto",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${waves})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100vw",
            minHeight: 200,
          }}
        >
          <div className="grid grid-cols-12 gap-4 py-5 lg:py-8">
            <div className="col-span-12 lg:col-span-6">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-2 lg:col-span-6" />
                <div className="col-span-4 lg:col-span-3">
                  <Card type="strategy" />
                </div>
                <div className="col-span-4 lg:col-span-3">
                  <Card type="design" />
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-2 lg:hidden" />
                <div className="col-span-4 lg:col-span-3">
                  <Card type="monetization" />
                </div>
                <div className="col-span-4 lg:col-span-3">
                  <Card type="innovation" />
                </div>

                <div className="col-span-1 lg:col-span-6" />
              </div>
            </div>
            {/* <div className="col-span-1 lg:col-span-3" /> */}

            {/* <div className="col-span-1 lg:hidden" />
            <div className="col-span-1 lg:hidden" />
            
            <div className="col-span-1 lg:col-span-3" /> */}
          </div>
          {/* <img
          src={waves}
          style={{
            width: "100vw",
            height: "inherit",
            objectFit: "fill",
          }}
          alt="waves"
        /> */}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
