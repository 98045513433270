// import logo from "./logo.svg";
import React, { useState, useEffect } from "react";
import "./App.css";
import AppRouter from "./appRouter";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Layout from "antd/lib/layout/layout";
import Modal from "./components/Modal";
// import { Audio } from "react-loader-spinner";
import WebFont from "webfontloader";

// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const images = [
      "./assets/homepage.png",
      "./assets/logo.png",
      "./assets/mail.png",
      "./assets/monetization-card.png",
      "./assets/strategy-card.png",
      "./assets/design-card.png",
      "./assets/innovation-card.png",
      "./assets/vertical-grid.png",
      "./assets/horizontal-grid.png",
    ];

    chacheImages(images);
  }, []);

  const chacheImages = async (images) => {
    // debugger;
    const promises = await images.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();

        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });

    await Promise.all(promises);

    await WebFont.load({
      custom: {
        families: ["Communist Sans", "Forma DJR Text Reg", "Forma DJR Text Bd"],
      },
    });

    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  return (
    <>
      <div className="App">
        <Router>
          <Layout /*className="flex flex-col justify-between"*/>
            <Header />

            <Modal visible={isLoading} />
            <AppRouter className="main" />
            <Footer />
          </Layout>
        </Router>
      </div>
    </>
  );
}

export default App;
