import React from "react";
import SimplePage from "../components/SimplePage";

function Strategy() {
  return (
    <div className="px-5 lg:px-24 pt-12">
      <SimplePage
        type="strategy"
        cards={["design", "monetization", "innovation"]}
        title={["We Identify and Resolve", "Issues that Block your Momentum"]}
        paragraphs={[
          "We build tactical programs that bring evolving business goals into alignment. We discover and resolve gaps between stated goals and customer experiences, product offerings and services. We create smart, effective, noise-free relationship models that make processes easier for internal and external stakeholders, constituents, customers, clients, partners and marketplace channels. We create revenue enhancing customer experience models that are practical and engaging. We clarify value propositions and audience to develop impactful, revenue generating relationships with your marketplace, vendors and partners.",
          "We develop marketing programs that deepen the solidarity of your brand experience. From under-utilized services, to a new product launch, we work with our clients to build cohesive, smart, elegant experiences, ecosystems and offerings.",
          "Our clients are asking themselves these questions when looking to shift or enhance their business strategy: Where am I stuck and unable to resolve a disconnection between how my product is offered and experienced? Is my Business Strategy fluid and well thought out, or, do I have drop-off points where the customer is missing something along the way? What processes are in place to facilitate Client/Customer Engagement and Product Sales? Am I delivering clear pathways and processes for new or potential clients who are not familiar with our services? Are the current User Experience channels used by my employees, customers, partners, distributors and vendors effective and clear? Do I have the visibility I need into my product line sales to make informed decisions? Do I effectively consider unexpected or expected seasonal variables? Who is engaged, disenfranchised or marginalized by how we do things in our digital and non-digital business practices? How do we connect Human Experiences in non-digital and digital worlds? What governance models are in place to facilitate clear processes and expectations for our employees, partners and customers? Where are there disconnections between my Brand Promise, intended Customer Experience and Reality? Where am I missing Revenue Opportunities? How can I improve my Sales Channels? Why is demand for my amazing product line not growing?",
          " ",
          "Micro Case Studies:",
          "Client: National Food Broker. Redefined user experience for managing sales and marketing for over 100 brands and 1200 products. Internal paper heavy processes created a time burden and confusing labyrinth to access sales data and field marketing materials. We created an efficient work-flow and a role-based Portal User Experience for sales people, client brands, retail stores, suppliers, channel partners, vendors, distribution companies and legal contracts. Executive Teams and Brand Managers were able to access real-time sales and marketing reports and visual maps from any perspective required.",
          "Client: University Medical School and Research Center. We developed User Experience for a Phase 3 Clinical Trial to engage potential registrants and facilitate entire patient life-cycle for case managers, social workers, Medical Doctors and regulatory agencies.",
        ]}
      />
    </div>
  );
}

export default Strategy;
