import React from "react";
import SimplePage from "../components/SimplePage";

function Design() {
  return (
    <div className="px-5 lg:px-24 pt-12">
      <SimplePage
        type="design"
        cards={["strategy", "monetization", "innovation"]}
        title={[
          "We Believe Listening and Brand Integrity",
          "are Essential to Impactful Visual Design",
        ]}
        paragraphs={[
          "We create fresh and vibrant branding for start-ups and new consumer products in development. We extend existing brands into new marketplaces and audiences through thoughtful messaging, design enhancements or complete rebranding.",
          "We develop all content needed for sales and marketing programs, partner and distribution channels and events. We create beautiful product packaging that clearly presents our clients’ unique identity. We build wire-frames and fast track prototypes to test and validate for stakeholders new user-experience directions prior to production. We provide our clients with smart foundations for all ongoing visual, content and marketing development.",
          "These are some of the questions we ask when we audit and develop your content and visual assets: What are your clients saying about you that does not resonate with your intended brand promise? What are potential clients saying about your marketplace that you need to listen and respond to? How are you responding to marketplace variables that are impacting your customers? How is your content and visual design out of step with what is possible? Where are there gaps between your offering and how it is presented?",
          " ",
          "Mini Case Studies:",
          "Client: Digital Newspaper for Europe's sixth largest city. Developed Website, User-Experience, Content Management and all Branding (Slogan, Tagline, Mission Statement, Email Marketing Programs, Marketing Sheets, Special Events Materials).",
          'Client: International Credit Card Company. Designed a variety of brand directions and user-experience prototypes to demonstrate and test possibilities for a new corporate initiative to expand from credit card services into the business of consumer banking. The project was called: "Build a Better Bank" and served as a guiding foundation for a multi-year expansion.',
        ]}
      />
    </div>
  );
}

export default Design;
