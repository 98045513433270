import React from "react";
import { Route, Routes } from "react-router-dom";

import Strategy from "./pages/Strategy";
import Design from "./pages/Design";
import Monetization from "./pages/Monetization";
import Innovation from "./pages/Innovation";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import OurWork from "./pages/OurWork";
import CaseStudy from "./pages/CaseStudy";

const AppRouter = () => {
  return (
    <Routes>
      <Route exact path={`${process.env.PUBLIC_URL}/`} element={<Home />} />
      <Route exact path={`${process.env.PUBLIC_URL}/home`} element={<Home />} />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/ourwork/strategy`}
        element={<Strategy />}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/ourwork/design`}
        element={<Design />}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/ourwork/monetization`}
        element={<Monetization />}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/ourwork/innovation`}
        element={<Innovation />}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/ourwork`}
        element={<OurWork />}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/aboutus`}
        element={<AboutUs />}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/casestudy`}
        element={<CaseStudy />}
      />
    </Routes>
  );
};

export default AppRouter;
