import React from "react";
import { Link } from "react-router-dom";
import Card from "../components/Card";

const bg = require("../assets/homepage.png");
const verticalGrid = require("../assets/vertical-grid.png");
const horizontalGrid = require("../assets/horizontal-grid.png");

function Home() {
  return (
    <div
      className="main"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        backgroundPositionX: "55%",
      }}
    >
      <div
        className="grid grid-cols-5 lg:grid-cols-8 px-12 lg:pl-24 lg:pr-0"
        style={{ minHeight: "79vh" }}
      >
        <div className="col-span-5 lg:col-span-2">
          <div
            style={{
              backgroundImage: `url(${verticalGrid})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "55%",
            }}
            className="h-auto lg:h-full"
          >
            <div className="homepage-tile-block text-bold">
              <p className="homepage-tile pl-6 lg:pl-16">
                <Link to="/aboutus">ABOUT US</Link>
              </p>
              <p className="homepage-tile pl-6 lg:pl-16">
                <Link to="/ourwork">OUR WORK</Link>
              </p>
              <p className="homepage-tile pl-6 lg:pl-16">
                <Link to="/casestudy">FEATURED CASE STUDY</Link>
              </p>
            </div>
          </div>
        </div>

        <div className="hidden lg:block lg:col-span-1" />

        <div className="col-span-5 flex lg:items-end">
          {/* <div> */}
          <div
            className="items-end"
            style={{
              backgroundImage: `url(${horizontalGrid})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="grid grid-cols-2 lg:grid-cols-10 gap-4 pt-6 pb-12">
              <div className="hidden lg:block lg:col-span-1" />
              <div className="col-span-1 lg:col-span-2">
                <Card type="strategy" />
              </div>
              <div className="col-span-1 lg:col-span-2">
                <Card type="design" />
              </div>
              <div className="col-span-1 lg:col-span-2">
                <Card type="monetization" />
              </div>
              <div className="col-span-1 lg:col-span-2">
                <Card type="innovation" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
