import React from "react";

import Card from "../components/Card";

const waves = require("../assets/waves.png");

const OurWork = () => {
  const data = [
    [
      "Logo & tagline (Visual Identity Systems)",
      "Brand Positioning",
      "Typography",
      "UI/UX Design",
      "Website Design",
      "Print Collateral",
      "Package Design",
    ],
    [
      "Content Strategy & Development",
      "Gap Analysis",
      "Marketing Communications",
      "Business Development: Inside Sales Restructuring",
      "Business Development: Partner Development",
      "Customer Realignment",
      "Audience Channel Development",
    ],
    [
      "Launch Strategies",
      "API Integration",
      "Product Sales Management",
      "Composite Application Development",
      "Discovery & Ideation",
      "Proof of Concept Testing",
      "Change Management",
    ],
  ];

  return (
    <div>
      <div className="px-5 lg:px-48 pt-12 pb-12">
        <div className="mb-12 lg:text-justify">
          <p>
            Smart businesses are always working towards the alignment of their
            identity, technology infrastructure and culture. Tap on the Icons
            below to see how we view our primary business channels and some of
            the questions we ask with our clients. Our services within these
            channels that we always consider when working with clients include:
          </p>
        </div>

        <div
          // style={{
          //   display: "flex",
          //   flexDirection: "row",
          //   justifyContent: "space-between",
          // }}
          className="grid sm:grid-cols-1 lg:grid-cols-10 mb-24 gap-4"
        >
          {data.map((el, index) => (
            <div
              className={
                index === 0
                  ? "col-span-3"
                  : index === 1
                  ? "col-span-4"
                  : "col-span-3"
              }
              key={JSON.stringify(el)}
            >
              <div className="grid grid-cols-1 gap-4">
                <div
                  // style={{ display: "flex", flexDirection: "column" }}
                  className="col-span-1"
                >
                  {el.map((key, index) => (
                    <div
                      className="flex w-full items-start mb-3"
                      key={`${index}-${el}`}
                    >
                      <div className="vertical-square" />
                      <div className="text-lg">{key}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="grid grid-cols-6 gap-12 mb-24">
          <div className="col-span-1 lg:hidden" />
          <div className="col-span-4 lg:col-span-1">
            <Card type="strategy" />
          </div>
          <div className="col-span-1 lg:hidden" />
          <div className="col-span-6 lg:col-span-5 lg:text-justify -mt-4 lg:mt-6">
            <p className="mb-8">
              Client: National Food Broker. Redefined user experience for
              managing sales and marketing for over 100 brands and 1200
              products. Internal paper heavy processes created a time burden and
              confusing labyrinth to access sales data and field marketing
              materials. We created an efficient work-flow and a role-based
              Portal User Experience for sales people, client brands, retail
              stores, suppliers, channel partners, vendors, distribution
              companies and legal contracts. Executive Teams and Brand Managers
              were able to access real-time sales and marketing reports and
              visual maps from any perspective required.
            </p>

            <p>
              Client: University Medical School and Research Center. We
              developed User Experience for a Phase 3 Clinical Trial to engage
              potential registrants and facilitate entire patient life-cycle for
              case managers, social workers, Medical Doctors and regulatory
              agencies.
            </p>
          </div>
        </div>

        <div className="hidden lg:block">
          <div className="grid lg:grid-cols-6 gap-12 mb-24">
            <div className="col-span-5 lg:text-justify mt-6">
              <p className="mb-8">
                Client: Digital Newspaper for Europe's sixth largest city.
                Developed Website, User-Experience, Content Management and all
                Branding (Slogan, Tagline, Mission Statement, Email Marketing
                Programs, Marketing Sheets, Special Events Materials).
              </p>
              <p>
                Client: International Credit Card Company. Designed a variety of
                brand directions and user-experience prototypes to demonstrate
                and test possibilities for a new corporate initiative to expand
                from credit card services into the business of consumer banking.
                The project was called: "Build a Better Bank" and served as a
                guiding foundation for a multi-year expansion.
              </p>
            </div>
            <div className="col-span-1 px-24 lg:px-0">
              <Card type="design" />
            </div>
          </div>
        </div>

        <div className="block lg:hidden">
          <div className="grid grid-cols-6 gap-12 mb-24">
            <div className="col-span-1 lg:hidden" />
            <div className="col-span-4">
              <Card type="design" />
            </div>
            <div className="col-span-1 lg:hidden" />
            <div className="col-span-6 lg:col-span-5 lg:text-justify -mt-4 lg:mt-6">
              <p className="mb-8">
                Client: Digital Newspaper for Europe's sixth largest city.
                Developed Website, User-Experience, Content Management and all
                Branding (Slogan, Tagline, Mission Statement, Email Marketing
                Programs, Marketing Sheets, Special Events Materials).
              </p>
              <p>
                Client: International Credit Card Company. Designed a variety of
                brand directions and user-experience prototypes to demonstrate
                and test possibilities for a new corporate initiative to expand
                from credit card services into the business of consumer banking.
                The project was called: "Build a Better Bank" and served as a
                guiding foundation for a multi-year expansion.
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-6 gap-12 mb-24">
          <div className="col-span-1 lg:hidden" />
          <div className="col-span-4 lg:col-span-1">
            <Card type="monetization" />
          </div>
          <div className="col-span-1 lg:hidden" />
          <div className="col-span-6 lg:col-span-5 lg:text-justify -mt-4 lg:mt-6">
            <p className="mb-8">
              Client: Social Media App. Redefined revenue strategy through
              targeted project goals that included: deepening the customer
              experience model by eliminating gaps and fall-off points;
              transforming the confusing, multi-page application into an
              intuitive, elegant and easy to use engagement model; and,
              creating, unobtrusive touch-points for meaningful sponsorship
              within the app. Developed go-to market sales and marketing
              programs based on “indication of interest” matrix that was created
              before and during the project development process.
            </p>
            <p>
              Client: Consulting Services Firm. Defined and Built a new, from
              the ground up, Business Development Strategy and Partner Channel
              Program that guided all revenue generation for this firm as it
              moved from 15 to 2500 employees.
            </p>
          </div>
        </div>

        <div className="hidden lg:block">
          <div className="grid grid-cols-6 gap-12 mb-6">
            <div className="col-span-5 lg:text-justify mt-6">
              <p className="mb-8">
                Client: Global Software Company. Developed a Multi-Channel
                Customer Engagement App that connected, in real-time, a customer
                with an urgent hardware issue, a customer service
                representative, a field service engineer and a parts
                availability database within a single-page multi-user
                experience. This was developed as a prototype to demonstrate how
                their clients can create next-gen composite customer service
                applications and workflows.
              </p>
              <p>
                Client: National Food Broker. Developed the first application
                workflow that integrated Salesforce.com and ESRI to show product
                sales via dashboard heat maps for a variety stakeholder
                requirements.
              </p>
            </div>

            <div className="col-span-1 px-24 lg:px-0">
              <Card type="innovation" />
            </div>
          </div>
        </div>

        <div className="lg:hidden block">
          <div className="grid grid-cols-6 gap-12 mb-6">
            <div className="col-span-1 lg:hidden" />
            <div className="col-span-4">
              <Card type="innovation" />
            </div>
            <div className="col-span-1 lg:hidden" />
            <div className="col-span-6 lg:col-span-5 lg:text-justify -mt-4 lg:mt-6">
              <p className="mb-8">
                Client: Global Software Company. Developed a Multi-Channel
                Customer Engagement App that connected, in real-time, a customer
                with an urgent hardware issue, a customer service
                representative, a field service engineer and a parts
                availability database within a single-page multi-user
                experience. This was developed as a prototype to demonstrate how
                their clients can create next-gen composite customer service
                applications and workflows.
              </p>
              <p>
                Client: National Food Broker. Developed the first application
                workflow that integrated Salesforce.com and ESRI to show product
                sales via dashboard heat maps for a variety stakeholder
                requirements.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${waves})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          // width: "100vw",
          height: 400,
        }}
        className="hidden lg:block w-full"
      />
    </div>
  );
};

export default OurWork;
