import React, { useState, useEffect } from "react";
import { Nav, NavLink, NavMenu } from "./NavbarElements";
import { Link, useLocation } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { Button } from "antd";

const logo = require("../assets/logo.png");
// const tagline = require("../assets/tagline.png");

const Header = () => {
  const location = useLocation();
  const shouldShowArray = ["/", "/home"];
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [toggleMenu, setToggleMenu] = useState(false);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <>
      <Nav
        style={
          location.pathname !== "/" && location.pathname !== "/home"
            ? {
                borderBottom: "6px solid #fa4616",
              }
            : { marginBottom: 10 }
        }
      >
        {shouldShowArray.indexOf(location.pathname) > -1 && (
          <div className="mt-8 relative">
            <Button
              style={{ float: "right", zIndex: 9999 }}
              // className="underline text-bold"
              onClick={(e) => {
                window.open(`mailto:explore@innovatenow.today`, "_blank");
                e.preventDefault();
              }}
            >
              <img
                src={require("../assets/mail.png")}
                width={50}
                height={24}
                alt="mail"
                style={{ float: "right" }}
              />
            </Button>
          </div>
        )}

        {shouldShowArray.indexOf(location.pathname) > -1 ? (
          <div className="nav-brand -mt-12">
            <Link to="/">
              <img src={logo} className="home-logo" alt="logo" />
            </Link>
            {/* <img src={tagline} style={{ width: "50vw" }} alt="tagline" /> */}
            <p className="tagline hidden lg:block text-bold mt-6">
              Advancing Elegant, Noise-Free Digital Experiences
            </p>
            {shouldShowArray.indexOf(location.pathname) === -1 && (
              <MenuOutlined onClick={toggleNav} className="block lg:hidden" />
            )}
          </div>
        ) : (
          <div className="nav-brand pt-4 mb-4 lg:mb-0">
            <Link to="/">
              <img src={logo} className="home-logo" alt="logo" />
            </Link>
            {/* <img src={tagline} style={{ width: "50vw" }} alt="tagline" /> */}
            <p className="tagline hidden lg:block text-bold mt-6">
              Advancing Elegant, Noise-Free Digital Experiences
            </p>
            {shouldShowArray.indexOf(location.pathname) === -1 && (
              <MenuOutlined onClick={toggleNav} className="block lg:hidden" />
            )}
          </div>
        )}

        {shouldShowArray.indexOf(location.pathname) === -1 && (
          <div>
            {(toggleMenu || screenWidth > 500) && (
              <NavMenu className="text-bold">
                <NavLink to="/aboutus">ABOUT US</NavLink>
                <NavLink to="/ourwork">OUR WORK</NavLink>
                <NavLink className="mb-4 lg:mb-0" to="/casestudy">
                  FEATURED CASE STUDY
                </NavLink>
              </NavMenu>
            )}
          </div>
        )}
      </Nav>
    </>
  );
};

export default Header;
