import React from "react";
import "./Modal.css";

const logo = require("../assets/logo.png");

const Modal = (props) => {
  return props.visible ? (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-body h-full">
          <div className="flex justify-center items-center h-full">
            <img src={logo} width={150} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
