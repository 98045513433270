import React from "react";
import SimplePage from "../components/SimplePage";

function Monetization() {
  return (
    <div className="px-5 lg:px-24 pt-12">
      <SimplePage
        type="monetization"
        cards={["strategy", "design", "innovation"]}
        title={[
          "We Create Value through",
          "Tactical Clarity and an Explorer's Mindset",
        ]}
        paragraphs={[
          "We are skilled at eliminating the noise between our clients and their revenue opportunities. We identify new revenue streams for products, services and communities. We re-energize marketplace opportunities for existing brands. We build multi-channel business development programs for start-ups and organizations entering new markets. We take Apps and websites that are missing the mark and re-define user-experiences to increase revenue engagement potential. We pull down fences, when necessary, and build effective governance models when our customers need to have better insight through focused, revenue producing business channels. We develop forward thinking prioritization plans in order to optimize resource to revenue ratios.",
          "We answer these questions with our clients: What is your current state and potential marketplace? Who and where is your current and most viable audience? Is there a customer/client base that you might not be connecting to/with? Are you working with partners, vendors, suppliers, distributors effectively? Are your products or services disconnected from new or available revenue streams? What is the best way to create new revenue channels?",
          " ",
          "Mini Case Studies:",
          "Client: Social Media App. Redefined revenue strategy through targeted project goals that included: deepening the customer experience model by eliminating gaps and fall-off points; transforming the confusing, multi-page application into an intuitive, elegant and easy to use engagement model; and, creating, unobtrusive touch-points for meaningful sponsorship within the app. Developed go-to market sales and marketing programs based on “indication of interest” matrix that was created before and during the project development process.",
          "Client: Consulting Services Firm. Defined and Built a new, from the ground up, Business Development Strategy and Partner Channel Program that guided all revenue generation for this firm as it moved from 15 to 2500 employees.",
        ]}
      />
    </div>
  );
}

export default Monetization;
