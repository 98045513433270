import React from "react";
import { useLocation } from "react-router-dom";
import { Button } from "antd";

const curve = require("../assets/footer-curve.png");

const Footer = () => {
  const hideCurveArray = ["/home", "/", "/aboutus"];

  const location = useLocation();

  const getFooterEmail = () => {
    switch (location.pathname) {
      case "/ourwork/design":
        return "Design@InnovateNow.TODAY";
      case "/ourwork/strategy":
        return "Strategy@InnovateNow.TODAY";
      case "/ourwork/innovation":
        return "Innovate@InnovateNow.TODAY";
      case "/ourwork/monetization":
        return "Monetize@InnovateNow.TODAY";
      default:
        return "Explore@InnovateNow.TODAY";
    }
  };

  if (location.pathname === "/" || location.pathname === "/home") return <></>;

  return (
    <div className="footer">
      <div
        className="px-5 lg:px-24 grid grid-cols-1 lg:grid-cols-3 gap-4 text-base lg:text-3xl"
        style={{
          marginTop: location.pathname === "/casestudy" ? -48 : 0,
          zIndex: 999,
        }}
      >
        {hideCurveArray.indexOf(location.pathname) === -1 && (
          <div
            className="absolute -ml-5 lg:-ml-24"
            style={{
              backgroundImage: `url(${curve})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              width: "100vw",
              minHeight: "6vw",
              marginTop: "calc(-6vw + 1px)",
            }}
          />
        )}
        <div className="col-span-1 lg:col-span-2 lg:self-center mt-4">
          <div className="text-bold flex justify-between lg:block">
            <span className="lg:mr-24">New York</span>
            <span className="lg:mr-24">Boston</span>
            <span>Bucharest</span>
          </div>
        </div>
        <div className="self-center font-bold col-span-1 lg:col-span-1 lg:text-right mt-4">
          <div className="">
            <Button
              className="text-bold"
              onClick={(e) => {
                window.open(`mailto:${getFooterEmail()}`, "_blank");
                e.preventDefault();
              }}
            >
              {getFooterEmail()}
            </Button>
          </div>
        </div>
      </div>
      <div className="px-5 lg:px-24 grid grid-cols-1 lg:grid-cols-3 gap-4 text-base lg:text-3xl">
        <div className="text-sm lg:text-xl col-span-1 lg:col-span-2">
          Copyright 2022 Innovate Now, LLC, All rights reserved
        </div>
      </div>
    </div>
  );
};

export default Footer;
