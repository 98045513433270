import React from "react";
import Card from "./Card";

const SimplePage = (props) => {
  return (
    <>
      <div className="grid grid-cols-8 gap-4">
        {/* <span>Test {props.type}</span>
         */}
        <div className="col-span-8 lg:col-span-7 lg:pr-12">
          <div className="grid grid-cols-6 gap-4 mb-12">
            <div className="col-span-1 lg:hidden" />
            <div className="col-span-4 lg:col-span-1">
              <Card type={props.type} />
            </div>
            <div className="col-span-1 lg:hidden" />
            <div className="text-2xl lg:text-4xl text-orange-700 mb-5 lg:px-24 mt-8 lg:mt-16 text-center col-span-6 lg:col-span-5">
              {props.title.map((el) => (
                <p key={el}>{el}</p>
              ))}
            </div>
          </div>
          {props.paragraphs.map((el) => (
            <div key={el.length}>
              {el === " " ? (
                <br />
              ) : (
                <p className="mb-5 lg:text-justify">{el}</p>
              )}
            </div>
          ))}
        </div>
        <div className="col-span-2 lg:hidden" />
        <div className="col-span-4 lg:col-span-1">
          {props.cards.map((el) => (
            <div className="mb-5" key={el}>
              <Card type={el} />
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-end lg:ml-96" style={{ maxWidth: "75vw" }}>
        <img
          src={require(`../assets/${props.type}-icon.png`)}
          width={
            props.type === "monetization" || props.type === "strategy"
              ? "40%"
              : "25%"
          }
          alt="type"
        />
      </div>
    </>
  );
};

export default SimplePage;
