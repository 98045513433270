import React from "react";

const efLogo = require("../assets/ef-logo.png");
const efName = require("../assets/ef-name.png");
const efCompass = require("../assets/ef-compass.png");

const yellow = require("../assets/yellow-trans.png");
const green = require("../assets/green-trans.png");
const blue = require("../assets/blue-trans.png");
const strawberries = require("../assets/strawberries.png");
const mushrooms = require("../assets/mushrooms.png");
const packages = require("../assets/packages.jpg");

const CaseStudy = () => {
  const data = [
    ["Logo, Tagline and all Visual Design", "Package Design", "Website design"],
    ["Content Creation", "Product Sales Sheets", "Marketing Displays"],
    [
      `Retail Store Partners: Engagement & Wholesale Purchasing Portal (store enrollment, select product, create purchase order, view
        all purchase orders, pay for product, receive paid invoice, download sales sheets).`,
      "Online Consumer Store",
      `Product Management Dashboard for both Retail Store Partners and Consumer Store (add a product, change pricing, add pictures, and shipping costs). All product/pricing changes made
        within management dashboard are updated in an encrypted database and pushed in real time to the wholesale and consumer purchasing process`,
      `API Development for PCI Compliant Payment Processing using Authorize.Net and integrated with PNC Bank.`,
      `Business Development (developed go to market strategy for retail stores)`,
    ],
  ];

  return (
    <div className="px-5 lg:px-24 pt-12 pb-12 lg:text-justify">
      <div style={{ width: "100%" }} className="flex justify-center mb-12">
        <div className="self-center mr-12">
          <img src={efName} width={500} alt="name" />
        </div>
        <div>
          <img src={efLogo} width={250} alt="name" />
        </div>
      </div>

      <div className="text-3xl text-orange-700 mb-5">
        PRODUCT LAUNCH - STARTUP
      </div>
      <p className="mb-5">
        The vision of Everlasting Fresh began with a great idea based on years
        of product development of freeze-dried fruits &amp; vegetables in one of
        the founder’s kitchen in New York’s Hudson Valley.
      </p>
      <p className="mb-5">
        The only way to manufacture on a large scale these products that friends
        and neighbors loved, was to use the same technology that NASA deploys
        with food sent into space: Lyophilization. This was also the only way to
        keep the fresh &amp; delicious taste along with 99 % of the nutrients
        found in each fruit and vegetable.
      </p>
      <p className="mb-5">
        Beyond that first decision, everything else, branding, packaging,
        technology for ecommerce and product management, marketing materials and
        everything related to monetization channels needed to be developed: A
        true Tabula Rasa; Blank Slate. It was at this point that Everlasting
        Fresh partnered with Innovate Now.
      </p>
      <p className="mb-5">
        Our first challenge was to develop the company tagline which reflects
        honestly the feedback from people who tried their products: Delicious!
        The founders wanted people to experience this American made product in
        their daily lives and during their hobbies and activities. And so, the
        tagline was created at the beginning of the project and serves as the
        company mantra and key relationship point with their customers: Pause
        with Delicious, Wherever You Are!
      </p>

      <p>Project Deliverables:</p>
      <div className="grid grid-cols-8 gap-4">
        <div className="lg:ml-8 pt-8 lg:mr-12 col-span-8 lg:col-span-5 text-xl">
          <div className="lg:flex">
            <div className="mr-5 lg:mr-12">
              {data[0].map((el) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // alignItems: "center",
                    marginBottom: 15,
                  }}
                  key={el}
                >
                  <div className="vertical-square" />
                  {el}
                </div>
              ))}
            </div>
            <div>
              {data[1].map((el) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // alignItems: "center",
                    marginBottom: 15,
                  }}
                  key={el}
                >
                  <div className="vertical-square" />
                  {el}
                </div>
              ))}
            </div>
          </div>
          <div>
            {data[2].map((el) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // alignItems: "center",
                  marginBottom: 15,
                }}
                key={el}
              >
                <div className="vertical-square" />
                {el}
              </div>
            ))}
          </div>
        </div>
        <div style={{ width: "100%" }} className="col-span-8 lg:col-span-3">
          <img src={efCompass} width="100%" alt="compass" />
        </div>
      </div>

      <div
        style={{
          backgroundImage: `url(${yellow})`,
          // height: 500,
          width: "100vw",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="-ml-5 lg:-ml-24 mt-12 px-5 lg:px-24 py-5 lg:py-12 pb-10 grid grid-cols-8 gap-4"
      >
        <div className="col-span-2 lg:hidden" />
        <div className="col-span-4 lg:col-span-2">
          <img src={strawberries} alt="strawberries" />
        </div>
        <div className="col-span-1" />
        <div className="col-span-8 lg:col-span-5">
          <div className="lg:text-3xl text-2xl lg:mb-12 mb-4 text-bold text-left">
            Timeless Branding
          </div>
          <div className="mb-4 lg:mb-12 lg:text-justify">
            “Everlasting Fresh enjoys timeless branding. The images and type are
            fresh, but not trendy. You can imagine that the packaging was done
            decades ago. And so, the design fits with the brand promise: It's
            going to last!”
          </div>
          <div className="float-right">
            <p>Roger Black</p>
            <p className="-mt-2">Chair, Type Network</p>
            <p className="-mt-2">Renowned Graphic Designer &amp; Typographer</p>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage: `url(${green})`,
          // height: 500,
          width: "100vw",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "right",
        }}
        className="-ml-5 lg:-ml-24 mt-12 px-5 lg:px-24 py-5 lg:py-12 pb-10 grid grid-cols-8 gap-4"
      >
        <div className="col-span-8 lg:col-span-5">
          <div className="lg:text-3xl text-2xl lg:mb-12 mb-4 text-bold text-left">
            Transparency, Compliance, Security
          </div>
          <div className="mb-4 lg:mb-12 lg:text-justify">
            “Working with Charles and the Innovate Now team in creating an
            E-commerce platform for Everlasting Fresh was a great experience
            from start to finish. Innovate Now did a great job of managing the
            communication between the client and our team during the enrollment
            and implementation process. They understand the importance of
            transparency and delivering compliance and security in their
            solutions. At PNC Merchant Services, we look forward to continue
            working with this team in bringing tailored payment processing
            solutions that help grow and develop new and current businesses.”
          </div>
          <div>
            <p>John W. Chae</p>
            <p className="-mt-2">Virtual Payment Advisor</p>
            <p className="-mt-2">PNC Business Banking</p>
          </div>
        </div>

        <div className="col-span-2 lg:col-span-1" />
        <div className="col-span-4 lg:col-span-2">
          <img src={mushrooms} alt="mushrooms" />
        </div>
        <div className="col-span-1 lg:hidden" />
      </div>

      <div
        style={{
          backgroundImage: `url(${blue})`,
          // height: 500,
          width: "100vw",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="-ml-5 lg:-ml-24 mt-12 px-5 lg:px-24 py-5 lg:py-12 pb-10 grid grid-cols-8 gap-4"
      >
        <div className="col-span-8">
          <p className="hidden lg:block lg:text-3xl text-2xl lg:mb-12 mb-4 text-bold text-left">
            Smart User Experience &amp; Beautiful Branding
          </p>
          <div className="block lg:hidden">
            <p className="text-2xl lg:mb-12 mb-4 text-bold text-center">
              Smart User Experience
            </p>
            <p className="text-2xl lg:mb-12 mb-4 text-bold text-center -mt-6">
              &amp;
            </p>
            <p className="text-2xl lg:mb-12 mb-4 text-bold text-center -mt-6">
              Beautiful Branding
            </p>
          </div>
          <p className="lg:text-justify mb-4 lg:mb-12">
            “We found in Innovate Now the right partner with expertise and
            talent to build our new business, Everlasting Fresh. Innovate Now
            created beautiful branding and packaging that elegantly reflects our
            product line to our customers. The user experience they developed
            for both our wholesale and consumer channels, including our product
            management portal, is smart and “noise-free” which we love. During
            every phase of the project, from creating product sales sheets to
            bank API integration, options were presented to us in a clear,
            thoughtful way. They continue to be our go-to partner for branding,
            technology and business strategy.”
          </p>
          <div className="float-right">
            <p>Nathan Jones</p>
            <p className="-mt-2">CEO</p>
            <p className="-mt-2">Everlasting Fresh</p>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <img src={packages} width={window.innerWidth * 0.75} alt="packages" />
      </div>
    </div>
  );
};

export default CaseStudy;
